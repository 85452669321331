<template>
  <div>
    <el-card class="card-box" v-show="stepActive === 0">
      <SubTitle title="企业营业执照填写"></SubTitle>
      <el-form
        label-position="left"
        :model="licenseForm"
        :rules="licenseRules"
        ref="licenseForm"
        class="demo-ruleForm"
        label-width="150px"
      >
        <el-form-item label="营业执照" class="idCardImg">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 660px;
              margin-top: 12px;
            "
          >
            <div>
              <div class="add-box">
                <div v-if="!licenseForm.idCardImage">
                  <el-upload
                    :action="uploadfile"
                    :on-success="successFileLicense"
                    :headers="importHeaders"
                    :limit="1"
                    auto-upload
                    :show-file-list="false"
                  >
                    <img :src="require('../../../assets/circle_add.png')" class="add-icon" alt />
                  </el-upload>
                </div>
                <img
                  v-if="licenseForm.idCardImage"
                  :src="licenseForm.idCardImage"
                  class="linceImg"
                  alt
                />
              </div>
              <div
                class="error-tips"
                v-if="isSubmit && stepActive === 0 && !licenseForm.idCardImage"
              >请上传营业执照照片</div>
            </div>
            <div class="dscri">
              <div>示例图</div>
              <div>请上传多合一营业执照原件图片或加盖公章复印件</div>
              <div>支持.jpg、.png、.jpeg格式，大小不超过8M</div>
              <div>
                <el-upload
                  :action="uploadfile"
                  :on-success="successFileLicense"
                  :headers="importHeaders"
                  :limit="1"
                  auto-upload
                  :show-file-list="false"
                >
                  <el-button size="small" type="primary">选择文件</el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="企业名称" prop="name">
          <el-input v-model="licenseForm.name" placeholder="请输入企业名称"></el-input>
        </el-form-item>

        <el-form-item label="统一社会信用代码" prop="idCardNumber">
          <el-input v-model="licenseForm.idCardNumber" placeholder="请输入企业统一社会信用代码"></el-input>
        </el-form-item>

        <el-form-item label="经营场所" prop="address">
          <el-input v-model="licenseForm.address" placeholder="请输入企业经营场所"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="submitForm('licenseForm')">下一步</el-button>
      </div>
    </el-card>
    <el-card class="card-box" v-show="stepActive === 1">
      <SubTitle title="企业法人信息填写"></SubTitle>
      <el-form
        label-position="left"
        :model="legalRuleForm"
        :rules="legalRules"
        ref="legalRuleForm"
        class="demo-ruleForm"
        label-width="150px"
      >
        <el-form-item label="法人姓名" prop="juridicalPersonName">
          <el-input v-model="legalRuleForm.juridicalPersonName" placeholder="请输入法人姓名"></el-input>
        </el-form-item>

        <el-form-item label="法人身份证号码" prop="juridicalPersonId">
          <el-input v-model="legalRuleForm.juridicalPersonId" placeholder="请输入法人身份证号码"></el-input>
        </el-form-item>

        <el-form-item label="法人身份证" class="idCardImg">
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 660px;
              margin-top: 12px;
            "
          >
            <div>
              <div class="img-box">
                <img
                  v-if="!legalRuleForm.juridicalPersonIdFont"
                  src="../../../assets/font.png"
                  class="idImg"
                  alt
                />
                <img
                  v-if="legalRuleForm.juridicalPersonIdFont"
                  :src="legalRuleForm.juridicalPersonIdFont"
                  class="idImg"
                  alt
                />
              </div>
              <div class="upload-btn">
                <el-upload
                  :action="uploadfile"
                  :on-success="successFileFront"
                  :headers="importHeaders"
                  :limit="1"
                  auto-upload
                  :show-file-list="false"
                >
                  <el-button size="small" type="primary">请上传身份证头像面</el-button>
                </el-upload>
              </div>
              <div
                class="error-tips"
                v-show="
                  stepActive === 2 &&
                  isSubmit &&
                  !legalRuleForm.juridicalPersonIdFont
                "
              >请上传身份证头像面照片</div>
            </div>
            <div>
              <div class="img-box">
                <img
                  v-if="!legalRuleForm.juridicalPersonIdBack"
                  src="../../../assets/bank.png"
                  class="idImg"
                  alt
                />
                <img
                  v-if="legalRuleForm.juridicalPersonIdBack"
                  :src="legalRuleForm.juridicalPersonIdBack"
                  class="idImg"
                  alt
                />
              </div>
              <div class="upload-btn">
                <el-upload
                  :action="uploadfile"
                  :on-success="successFileBack"
                  :headers="importHeaders"
                  :limit="1"
                  :show-file-list="false"
                >
                  <el-button size="small" type="primary">请上传身份证国徽面</el-button>
                </el-upload>
              </div>
              <div
                class="error-tips"
                v-show="
                  stepActive === 1 &&
                  isSubmit &&
                  !legalRuleForm.juridicalPersonIdBack
                "
              >请上传身份证国徽面照片</div>
            </div>
          </div>
          <div class="dscri">
            <div>
              <span class="font-color-danger">*</span>
              请保证身份证照片四角完整，亮度均匀，内容清晰
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="submitForm('legalRuleForm')">企业认证</el-button>
      </div>
    </el-card>
    <!-- <el-card class="card-box" v-show="stepActive === 2">
      <SubTitle title="企业开户信息填写"></SubTitle>
      <div v-loading="loading" element-loading-text="发起认证中...">
        <el-form
          label-position="left"
          :model="bankRuleForm"
          :rules="bankRules"
          ref="bankRuleForm"
          class="demo-ruleForm"
          label-width="130px"
        >
          <el-form-item label="对公银行账号" prop="bankCode">
            <el-input v-model="bankRuleForm.bankCode" placeholder="请输入对公银行账号"></el-input>
          </el-form-item>

          <el-form-item label="开户银行" prop="bankName">
            <el-input v-model="bankRuleForm.bankName" placeholder="请输入对公银行名称，无需填写支行或分行"></el-input>
          </el-form-item>

          <el-form-item label="开户行所在地" prop="bankArea">
            <District :separator="','" v-model="bankRuleForm.bankArea"></District>
          </el-form-item>

          <el-form-item label="开户银行支行" prop="bankBranch">
            <el-input v-model="bankRuleForm.bankBranch" placeholder="请输入对公银行支行名称"></el-input>
          </el-form-item>

          <el-form-item label="支行联行号" prop="bankBranchCode">
            <el-input v-model="bankRuleForm.bankBranchCode" placeholder="请输入对公银行支行的12位联行号"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button type="primary" @click="submitForm('bankRuleForm')">企业认证</el-button>
        </div>
      </div>
    </el-card>-->
  </div>
</template>

<script>
import moment from 'moment';
import * as _ from 'lodash';
import { idCardNumberReg } from '../../../lib/global';
//接口：
import { api } from '/src/api/base';
import { generatePath } from '../../../api/base/index';
//查询企业信息
export const enterpriseDescribe = api()('enterprise.describe.json');
//编辑企业信息
export const enterpriseEdit = api()('enterprise.edit.json');
// 发起认证
export const enterpriseStartAuth = api()('enterprise.start.auth.json');
//绑定银行卡
export const userSelfBankCardBind = api()('user.self.bank.card.bind.json');

export default {
  components: {
    SubTitle: () => import('/src/components/subTitle.vue'),
    // District: () => import('/src/components/district.vue')
  },
  props: {
    stepActive: Number,
    admin: Object
  },
  data () {
    // 法人身份证校验
    var validatorIdCardNumber = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入法人身份证号'));
      } else {
        const now = moment().format('YYYY-MM-DD HH:mm:ss');
        const oldDate = moment(
          `${value.substr(6, 4)}-${value.substr(10, 2)}-${value.substr(12, 2)}`
        )
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        const seconds = moment(now, 'YYYY-MM-DD HH:mm:ss').diff(
          moment(oldDate, 'YYYY-MM-DD HH:mm:ss'),
          'seconds'
        );
        if (!idCardNumberReg.test(value)) {
          callback(new Error('请输入正确的身份证号'));
        }
        if (idCardNumberReg.test(value) && seconds < 18 * 365 * 24 * 60 * 60) {
          callback(new Error('法人年龄未满18周岁！'));
        }
        callback();
      }
    };
    // 企业统一社会信用代码校验
    var validatorLicenceNumber = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入企业统一社会信用代码'));
      } else {
        var patrn = /^[0-9A-Z]+$/; //18位校验及大写校验
        if (value.length != 18 || !patrn.test(value)) {
          callback(new Error('请输入有效的企业统一社会信用代码'));
        } else {
          var Ancode; //统一社会信用代码的每一个值
          var Ancodevalue; //统一社会信用代码每一个值的权重
          var total = 0;
          var weightedfactors = [
            1,
            3,
            9,
            27,
            19,
            26,
            16,
            17,
            20,
            29,
            25,
            13,
            8,
            24,
            10,
            30,
            28
          ]; //加权因子
          var str = '0123456789ABCDEFGHJKLMNPQRTUWXY'; //不用I、O、S、V、Z
          for (var i = 0; i < value.length - 1; i++) {
            Ancode = value.substring(i, i + 1);
            Ancodevalue = str.indexOf(Ancode);
            total = total + Ancodevalue * weightedfactors[i]; //权重与加权因子相乘之和
          }
          var logiccheckcode = 31 - (total % 31);
          if (logiccheckcode == 31) {
            logiccheckcode = 0;
          }
          var Str =
            '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y';
          var Array_Str = Str.split(',');
          logiccheckcode = Array_Str[logiccheckcode];

          var checkcode = value.substring(17, 18);
          if (logiccheckcode != checkcode) {
            callback(new Error('请输入有效的企业统一社会信用代码'));
          }
          callback();
        }
      }
    };
    return {
      licenseForm: {
        idCardImage: '',
        name: '',
        idCardNumber: '',
        address: ''
      },
      licenseRules: {
        idCardImage: [
          { required: true, message: '请上传企业营业执照图片', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        address: [
          { required: true, message: '请输入企业经营场所', trigger: 'blur' }
        ],
        idCardNumber: [
          {
            validator: validatorLicenceNumber,
            required: true,
            trigger: ['change', 'blur']
          }
        ]
      },
      isSubmit: false,
      legalRuleForm: {
        juridicalPersonName: '',
        juridicalPersonId: '',
        juridicalPersonIdFont: '',
        juridicalPersonIdBack: ''
      },
      legalRules: {
        juridicalPersonName: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' }
        ],
        juridicalPersonIdFont: [
          { required: true, message: '请上传法人身份证头像面', trigger: 'blur' }
        ],
        juridicalPersonIdBack: [
          { required: true, message: '请上传法人身份证国徽面', trigger: 'blur' }
        ],
        juridicalPersonId: [
          {
            validator: validatorIdCardNumber,
            required: true,
            trigger: ['change', 'blur']
          }
        ]
      },
      bankRuleForm: {
        bankCode: '',
        bankName: '',
        bankArea: '',
        bankBranch: '',
        bankBranchCode: ''
      },
      bankRules: {
        bankCode: [
          { required: true, message: '请输入对公银行账号', trigger: 'blur' }
        ],
        bankName: [
          {
            required: true,
            message: '请输入对公银行名称，无需填写支行或分行',
            trigger: 'blur'
          }
        ],
        bankArea: [
          {
            required: true,
            message: '请输入对公银行支行所在省市',
            trigger: 'blur'
          }
        ],
        bankBranch: [
          {
            required: true,
            message: '请输入对公银行支行名称',
            trigger: 'blur'
          }
        ],
        bankBranchCode: [
          {
            required: true,
            message: '请输入对公银行支行的12位联行号',
            trigger: 'blur'
          }
        ]
      },
      uploadfile: generatePath('enterprise.upload.json'),
      importHeaders: {
        Authorization: window.localStorage.getItem('token')
      },
      loading: false,
      code: '',
      authenticateState: '',
    };
  },
  created () {
    this.code =
      this.$route.query.code || window.localStorage.getItem('enterprise');
    this.getEnterpriseInfo();
  },
  methods: {
    async getEnterpriseInfo () {
      console.log(1111);
      const res = await enterpriseDescribe({
        code: this.code
      });
      Object.keys(this.licenseForm).map(it => {
        this.licenseForm[it] = res[it];
      });
      Object.keys(this.legalRuleForm).map(it => {
        this.legalRuleForm[it] = res[it];
      });
      // this.bankRuleForm.bankCode = res.bankCard.accountCode;
      // this.bankRuleForm.bankName = res.bankCard.bankName;
      // this.bankRuleForm.bankArea = res.bankCard.bankArea;
      // this.bankRuleForm.bankBranch = res.bankCard.branchBank;
      // this.bankRuleForm.bankBranchCode = res.bankCard.branchCode;
      // this.authenticateState = res.authenticateState
    },
    submitForm (formName) {

      if (this.stepActive === 0 || this.stepActive === 1) {
        this.isSubmit = true;
      }
      if (this.stepActive === 0 && !this.admin) {
        this.$message({
          message: '警告!请先设置管理员(该人员须公众号上认证过的成员)',
          type: 'warning'
        });
        return
      }
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let _params = {};
          switch (this.stepActive) {
            case 0:
              if (this.stepActive === 0 && !this.licenseForm.idCardImage)
                return;
              console.log(this.licenseForm.idCardImage, this.stepActive, 222);
              _params = this.getParams(this.licenseForm);
              break;
            case 1:
              if (
                this.stepActive === 1 &&
                (!this.legalRuleForm.juridicalPersonIdFont ||
                  !this.legalRuleForm.juridicalPersonIdBack)
              )
                return;
              _params = this.getParams(this.legalRuleForm);
              break;
            // case 2:
            //   _params = this.getParams(this.bankRuleForm);
            //   break;
          }
          console.log(_params);
          if (this.stepActive === 0) {
            await enterpriseEdit({
              code: this.code,
              ..._params
            });
            this.$refs[formName].resetFields();
            this.isSubmit = false;
            this.loading = false;
            this.toNext();
          }
          if (this.stepActive === 1) {
            this.loading = true;
            await enterpriseEdit({
              code: this.code,
              ..._params
            });
            const res = await enterpriseStartAuth({
              code: this.code,
              redirectUrl: window.location.href
            });
            window.localStorage.setItem('authUrl', res)
            this.$refs[formName].resetFields();
            this.isSubmit = false;
            this.loading = false;
            this.toNext();
          }

        } else {
          return false;
        }
      });
    },
    getParams (form) {

      const params = {};
      Object.keys(form).forEach(key => {
        const it = form[key];
        if (_.isDate(it)) {
          params[key] = moment(it).format('YYYY-MM-DD HH:mm');
          return;
        }
        if (_.isArray(it)) {
          params[key] = it.join(',');
          return;
        }
        params[key] = it;
      });
      return params;
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.legalRuleForm.juridicalPersonIdFont = '';
      this.legalRuleForm.juridicalPersonIdBack = '';
    },
    successFileLicense (res) {
      this.licenseForm.idCardImage = res.data;
    },
    successFileFront (res) {
      this.legalRuleForm.juridicalPersonIdFont = res.data;
    },
    successFileBack (res) {
      this.legalRuleForm.juridicalPersonIdBack = res.data;
    },
    toNext () {
      this.$emit('update:stepActive', this.stepActive + 1);
    }
  }
};
</script>

<style>
.card-box .el-card__body {
  text-align: left;
}
.upload-btn .el-upload {
  width: 100%;
}
.upload-btn .el-button {
  width: 100%;
  border-radius: 0 0 8px 8px;
  position: relative;
  top: -6px;
}
.idCardImg .el-form-item__label::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
</style>
<style lang="scss" scoped>
.demo-ruleForm {
  margin-left: 31px;
  margin-right: 25px;
}
.select-box {
  height: 30px;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  background: #f1f1f1;
  color: #545454;
  border-radius: 30px;
  margin-bottom: 34px;
  div {
    line-height: 30px;
    flex-grow: 1;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
  }
}
.select-box .active {
  background: #4963f4;
  color: #ffffff;
}
.code-img {
  text-align: center;
  padding: 55px 20px 39px;
  background-color: #f2f9fd;
  margin-bottom: 58px;
  div {
    font-size: 14px;
    color: #545454;
    margin-top: 39px;
  }
}
.dscri {
  font-size: 12px;
  color: #aaaaaa;
  margin-top: 20px;
  margin-bottom: 20px;
}
.img-box {
  background-color: #f2f9fd;
  padding: 1px 1px 0 1px;
  border-radius: 8px 8px 0 0;
}
.idImg {
  width: 298px;
  height: 183px;
  border-radius: 8px 8px 0 0;
}
.add-box {
  border: 1px solid #e9eff7;
  border-radius: 8px;
  height: 183px;
  div {
    width: 298px;
    height: 183px;
    text-align: center;
  }
  .add-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
    position: relative;
    top: 78px;
  }
}
.linceImg {
  width: 298px;
  height: 183px;
  border-radius: 8px;
}
</style>
